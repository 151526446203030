// Components
import { ProofList } from 'src/components/folders';

// Hooks
import { useFolderPage } from 'src/components/folders/FolderPageContext';
import { useProofsFilteredByEntryId } from 'src/features/proofs/hooks';

export const ProofListContainer = () => {
  const { selectedEntryId } = useFolderPage();

  const { proofs, isLoading } = useProofsFilteredByEntryId(selectedEntryId as string);

  if (isLoading) {
    return <p>Loading ...</p>;
  }

  return <ProofList proofs={proofs} />;
};
