import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { Row } from 'src/components/atoms/Row';
import Text from 'src/components/atoms/Text';
import { PDFDocument } from 'src/components/molecules/PDFViewer/PDFViewer';
import PDFModal from 'src/components/organisms/PDFModal/PDFModal';
import { IncomeVerification } from 'src/ts/interfaces/incomeVerification';
import { useDebouncedCallback } from 'use-debounce';
import {
  ReasonCodes,
  getCalendarIconColor,
  getGrosspayIconColor,
  getIncomeSourceIconColor,
  getPersonIconColor
} from './helper';
import { Container } from './styles';

const IncomeCalculationTileForm = ({
  document,
  reason_codes,
  onSave
}: {
  document: IncomeVerification;
  reason_codes: ReasonCodes;
  onSave: (formData: IncomeVerification) => void;
}) => {
  const methods = useForm<IncomeVerification>({ defaultValues: document });

  const handleOnSave = useDebouncedCallback((data: IncomeVerification) => {
    data = { ...data, index: document.index, file: document.file, docId: document.docId };

    const payPeriodDateRange = data.payPeriodDateRange;
    if (payPeriodDateRange === '') {
      data = {
        ...data,
        payPeriodStartDate: '',
        payPeriodEndDate: ''
      };

      onSave(data);
      return;
    }

    const payPeriodDateRangeSplit = data.payPeriodDateRange.split(' - ');

    if (payPeriodDateRangeSplit.length === 2) {
      const payPeriodStartDate = payPeriodDateRangeSplit[0];
      const payPeriodEndDate = payPeriodDateRangeSplit[1];
      data = {
        ...data,
        payPeriodStartDate,
        payPeriodEndDate
      };
    }

    onSave(data);
  }, 2000);

  const [showModal, setShowModal] = useState(false);
  const pdfDocument: PDFDocument = {
    key: document.docId,
    id: document.id,
    file: document.file,
    fileName: document.fileName
  };

  return (
    <>
      <PDFModal document={pdfDocument} showModal={showModal} setShowModal={setShowModal}></PDFModal>
      <Container>
        <Row gap={1} justify="space-between" alignItems="center">
          <Row.Col>
            <Text isBold={true}>Doc ID: {document?.docId} </Text>
          </Row.Col>
          <Row.Col> (Doc {document?.index + 1})</Row.Col>
          <Row.Col>
            <Button
              name="view-income-verification-pdf"
              variant={ButtonVariant.outline}
              onClick={() => setShowModal(true)}
            >
              View PDF
            </Button>
          </Row.Col>
        </Row>
        <Gap height={1} />
        <FormProvider {...methods}>
          <form onChange={methods.handleSubmit(handleOnSave)}>
            <Row>
              <Row.Col size={2}>
                <Icon
                  color={getCalendarIconColor(reason_codes)}
                  className="income-verification-icons"
                  icon="calendar"
                />
              </Row.Col>
              <Row.Col size={10}>
                <InputText
                  name="payPeriodDateRange"
                  type={InputTextType.text}
                  placeholder=""
                  showOptionalLabel={false}
                ></InputText>
              </Row.Col>
            </Row>
            <Row>
              <Row.Col size={2}>
                <Icon
                  color={getPersonIconColor(reason_codes)}
                  className="income-verification-icons"
                  icon="person"
                />
              </Row.Col>
              <Row.Col size={10}>
                <InputText
                  name="recipientName"
                  type={InputTextType.text}
                  placeholder=""
                  showOptionalLabel={false}
                ></InputText>
              </Row.Col>
            </Row>
            <Row>
              <Row.Col size={2}>
                <Icon
                  strokeColor={getIncomeSourceIconColor(reason_codes)}
                  className="income-verification-icons"
                  icon="income-source"
                />
              </Row.Col>
              <Row.Col size={10}>
                <InputText
                  name="incomeSource"
                  type={InputTextType.text}
                  placeholder=""
                  showOptionalLabel={false}
                ></InputText>
              </Row.Col>
            </Row>
            <Row>
              <Row.Col size={2}>
                <Icon
                  strokeColor={getGrosspayIconColor(reason_codes)}
                  className="income-verification-icons"
                  icon="money"
                />
              </Row.Col>
              <Row.Col size={10}>
                <InputText
                  name="grossPay"
                  type={InputTextType.text}
                  placeholder=""
                  showOptionalLabel={false}
                ></InputText>
              </Row.Col>
            </Row>
          </form>
        </FormProvider>
      </Container>
    </>
  );
};

export default IncomeCalculationTileForm;
