import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Gap from 'src/components/atoms/Gap';
import { Icon, IconColor } from 'src/components/atoms/Icon';
import { InputText, InputTextType } from 'src/components/atoms/InputText';
import { Row } from 'src/components/atoms/Row';
import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';
import { ToolTip } from 'src/components/atoms/ToolTip';
import { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { ControlledDatePicker } from 'src/components/molecules/DatePicker';
import PDFViewer from 'src/components/molecules/PDFViewer/PDFViewer';
import { EmbedMode } from 'src/ts/enums/pdfViewer';
import { IncomeVerification } from 'src/ts/interfaces/incomeVerification';
import { Container, PdfViewerWrapper } from './styles';

const IncomeVerificationForm = ({
  currentDocument,
  totalNumberOfDocuments,
  onSave
}: {
  currentDocument: IncomeVerification;
  totalNumberOfDocuments: number;
  onSave: (formData: IncomeVerification) => void;
}) => {
  const methods = useForm<IncomeVerification>({ defaultValues: currentDocument });

  const handleOnSave = (data: IncomeVerification) => {
    data = {
      ...data,
      index: currentDocument.index,
      file: currentDocument.file,
      docId: currentDocument.docId
    };
    onSave(data);
  };

  const payPeriodStartDate = methods.watch('payPeriodStartDate');

  useEffect(() => {
    if (payPeriodStartDate !== '' && payPeriodStartDate !== undefined) {
      handleOnSave(methods.getValues());
    }
  }, [payPeriodStartDate]);

  const payPeriodEndDate = methods.watch('payPeriodEndDate');

  useEffect(() => {
    if (payPeriodEndDate !== '' && payPeriodEndDate !== undefined) {
      handleOnSave(methods.getValues());
    }
  }, [payPeriodEndDate]);

  return (
    <Row gap={1}>
      <PdfViewerWrapper size={3.5}>
        <PDFViewer
          id={currentDocument.id}
          file={currentDocument.file}
          fileName={currentDocument.fileName}
          embedMode={EmbedMode.SIZED_CONTAINER}
          height="85vh"
          showZoomControl
        ></PDFViewer>
      </PdfViewerWrapper>
      <Row.Col size={3}>
        <Text isBold={true} align={TextAlign.center} variant={TextVariant.h3}>
          Doc ID: {currentDocument.docId}
        </Text>
        <Gap height={0.25} />
        <Text isBold={true} align={TextAlign.center}>
          Document {currentDocument.index + 1} of {totalNumberOfDocuments}
        </Text>
        <Gap height={4} />
        <FormProvider {...methods}>
          <form onBlur={methods.handleSubmit(handleOnSave)}>
            <Container>
              <Row gap={3}>
                <Row.Col size={4}>
                  <Row>
                    <Row.Col>
                      <Text align={TextAlign.left}>Pay Period Start Date</Text>
                    </Row.Col>
                    <Row.Col>
                      <ToolTip
                        direction={ToolTipDirection.top}
                        content="Enter the first day of the pay period noted on the document. You may also see this as 'period start', 'period starting', 'period beginning', or 'pay period' with a given range."
                      >
                        <Icon
                          className="income-verification-tooltip"
                          color={IconColor.blue700}
                          icon="help"
                        />
                      </ToolTip>
                    </Row.Col>
                  </Row>
                  <Gap height={0.5} />
                  <Row>
                    <ControlledDatePicker
                      className="income-verification-date-picker"
                      name="payPeriodStartDate"
                      selectsStart
                    />
                  </Row>
                </Row.Col>
                <Row.Col size={4}>
                  <Row>
                    <Row.Col>
                      <Text align={TextAlign.left}>Pay Period End Date</Text>
                    </Row.Col>
                    <Row.Col>
                      <ToolTip
                        direction={ToolTipDirection.top}
                        content="Enter the last/final day of the pay period noted on the document. You may also see this as 'period end', 'period ending', 'pay end date', or 'pay period' with a given range."
                      >
                        <Icon
                          className="income-verification-tooltip"
                          color={IconColor.blue700}
                          icon="help"
                        />
                      </ToolTip>
                    </Row.Col>
                  </Row>
                  <Gap height={0.5} />
                  <Row>
                    <ControlledDatePicker
                      className="income-verification-date-picker"
                      name="payPeriodEndDate"
                      selectsStart
                    />
                  </Row>
                </Row.Col>
              </Row>
              <Row gap={3}>
                <Row.Col size={4}>
                  <Row>
                    <Row.Col>
                      <Text align={TextAlign.left}>Recipient Name</Text>
                    </Row.Col>
                    <Row.Col>
                      <ToolTip
                        direction={ToolTipDirection.top}
                        content="Enter the name of the individual or entity to whom the document is addressed. You may find this under 'Employee Name', 'Payee Name', 'Account Holder Name', 'Beneficiary', etc."
                      >
                        <Icon
                          className="income-verification-tooltip"
                          color={IconColor.blue700}
                          icon="help"
                        />
                      </ToolTip>
                    </Row.Col>
                  </Row>
                  <Gap height={0.5} />
                  <Row>
                    <Row.Col size={12}>
                      <InputText
                        name="recipientName"
                        type={InputTextType.text}
                        placeholder=""
                        defaultValue={currentDocument.recipientName}
                        showOptionalLabel={false}
                      ></InputText>
                    </Row.Col>
                  </Row>
                </Row.Col>
                <Row.Col size={4}>
                  <Row>
                    <Row.Col>
                      <Text align={TextAlign.left}>Gross Pay</Text>
                    </Row.Col>
                    <Row.Col>
                      <ToolTip
                        direction={ToolTipDirection.top}
                        content="Enter the total amount of income earned before deductions or taxes. This includes salary, wages, bonuses, and any other forms of compensation before any deductions are taken out. You may also see this amount under 'total gross', 'total', or 'gross benefit amount'."
                      >
                        <Icon
                          className="income-verification-tooltip"
                          color={IconColor.blue700}
                          icon="help"
                        />
                      </ToolTip>
                    </Row.Col>
                  </Row>
                  <Gap height={0.5} />
                  <Row>
                    <Row.Col size={12}>
                      <InputText
                        name="grossPay"
                        type={InputTextType.text}
                        placeholder=""
                        defaultValue={currentDocument.grossPay}
                        showOptionalLabel={false}
                      ></InputText>
                    </Row.Col>
                  </Row>
                </Row.Col>
              </Row>
              <Row gap={3}>
                <Row.Col size={4}>
                  <Row>
                    <Row.Col>
                      <Text align={TextAlign.left}>Income Source</Text>
                    </Row.Col>
                    <Row.Col>
                      <ToolTip
                        direction={ToolTipDirection.top}
                        content="Enter the payer noted in the document. This may be listed near the top of the document, typically alongside your personal information. You may also find it under 'Employer Name', 'Employer Information', 'Benefits Program', 'Organization', etc."
                      >
                        <Icon
                          className="income-verification-tooltip"
                          color={IconColor.blue700}
                          icon="help"
                        />
                      </ToolTip>
                    </Row.Col>
                  </Row>
                  <Gap height={0.5} />
                  <Row>
                    <Row.Col size={12}>
                      <InputText
                        name="incomeSource"
                        type={InputTextType.text}
                        placeholder=""
                        defaultValue={currentDocument.incomeSource}
                        showOptionalLabel={false}
                      ></InputText>
                    </Row.Col>
                  </Row>
                </Row.Col>
                <Row.Col size={4}>
                  <div></div>
                </Row.Col>
              </Row>
            </Container>
          </form>
        </FormProvider>
      </Row.Col>
    </Row>
  );
};

export default IncomeVerificationForm;
