export enum FeatureFlagKey {
  CLEAN_PROCEED_WITH_CAUTION = 'clean-proceed-with-caution',
  EXTENDED_FA_DOCUMENT_TYPES = 'extended-fa-document-types',
  NEW_REPORT_ENDPOINT = 'new-report-endpoint',
  INCOME_VERIFICATION_PROPERTY_TOGGLE = 'income-verification-property-toggle',
  INCOME_VERIFICATION = 'income-calculation',
  MONTHLY_GROSS_INCOME_FDE_INBOX_COLUMN = 'monthly-gross-income-fde-inbox-column',
  MONTHLY_GROSS_INCOME_FOLDER_REVIEW = 'folder-review-monthly-gross-income',
  DISPUTE_BUTTON_FOLDER_REVIEW = 'folder-review-dispute-button',
  REPORT_PAGE_REDESIGN = 'results-summary-redesign',
  HELP_AND_RESOURCES_UPDATE = 'help-and-resources-update',
  USERFLOW = 'user-flow'
}

export enum FeatureFlagProjectName {
  FRAUD_PLATFORM = 'fraud-platform'
}
