import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';
import { DOCUMENT_TYPES } from '../constants';
import getDefaultTextLang from './getDefaultTextLang';

export const getDocumentTypes = (translate?: (key: ITextLangKeys) => string) => {
  const t = !translate ? getDefaultTextLang : translate;
  return [
    {
      key: DOCUMENT_TYPES[0].key,
      value: DOCUMENT_TYPES[0].value,
      label: t('dup_document_type_paystub'),
      defaultValue: 2
    }, // PAYSTUB
    {
      key: DOCUMENT_TYPES[1].key,
      value: DOCUMENT_TYPES[1].value,
      label: t('dup_document_type_bank_statement'),
      defaultValue: 2
    }, // BANK_STATEMENT
    {
      key: DOCUMENT_TYPES[2].key,
      value: DOCUMENT_TYPES[2].value,
      label: t('dup_document_type_cash_app_statement'),
      defaultValue: 0
    },
    {
      key: DOCUMENT_TYPES[3].key,
      value: DOCUMENT_TYPES[3].value,
      label: t('dup_document_type_credit_debit_card_statement'),
      defaultValue: 0
    },
    {
      key: DOCUMENT_TYPES[4].key,
      value: DOCUMENT_TYPES[4].value,
      label: t('dup_document_type_department_of_veterans_affairs_benefit_letter'),
      defaultValue: 0
    },
    {
      key: DOCUMENT_TYPES[5].key,
      value: DOCUMENT_TYPES[5].value,
      label: t('dup_document_type_investment_account'),
      defaultValue: 0
    },
    {
      key: DOCUMENT_TYPES[6].key,
      value: DOCUMENT_TYPES[6].value,
      label: t('dup_document_type_social_security_benefits_letter'),
      defaultValue: 0
    },
    {
      key: DOCUMENT_TYPES[7].key,
      value: DOCUMENT_TYPES[7].value,
      label: t('dup_document_type_social_security_statement'),
      defaultValue: 0
    },
    {
      key: DOCUMENT_TYPES[8].key,
      value: DOCUMENT_TYPES[8].value,
      label: t('dup_document_type_tax_transcript'),
      defaultValue: 0
    },
    {
      key: DOCUMENT_TYPES[9].key,
      value: DOCUMENT_TYPES[9].value,
      label: t('dup_document_type_utility_bill'),
      defaultValue: 0
    }
  ];
};

export const getOldDocumentTypes = (translate?: (key: ITextLangKeys) => string) => {
  const t = !translate ? getDefaultTextLang : translate;
  return [
    {
      key: DOCUMENT_TYPES[0].key,
      value: DOCUMENT_TYPES[0].value,
      label: t('dup_document_type_paystub'),
      defaultValue: 2
    }, // PAYSTUB
    {
      key: DOCUMENT_TYPES[1].key,
      value: DOCUMENT_TYPES[1].value,
      label: t('dup_document_type_bank_statement'),
      defaultValue: 2
    } // BANK_STATEMENT
  ];
};
