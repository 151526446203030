// Vendor
import { FC } from 'react';

// Components
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import { Line } from 'src/components/atoms/Line';
import { Row } from 'src/components/atoms/Row';
import { ProofListTable, ProofListTableProps } from 'src/components/folders/ProofListTable';
import { ShowState } from 'src/components/molecules/ShowState';
import { BulkSelectDoc } from 'src/pages/ReviewPage/components/BulkSelectDoc';
import FooterActions from './components/FooterActions';

// Hooks
import { useFolderPage } from 'src/components/folders/FolderPageContext';
import { useEntryById } from 'src/features/entries/hooks';
import { useProofUpdateNote, useProofUpdateState } from 'src/features/proofs/hooks';
import useUserRoles from 'src/features/auth/hooks/useUserRoles';

const ProofList: FC<Pick<ProofListTableProps, 'proofs'>> = ({ proofs }) => {
  const { selectedEntryId, handleDownload, handleTextFields, handleVisibility } = useFolderPage();
  const { entry } = useEntryById(selectedEntryId as string);
  const { onUpdateNote } = useProofUpdateNote();
  const { isAccountRepresentative } = useUserRoles();

  const { onCheckProofs, onCheckProof, getCheckedAll, onUpdateSelected, getIsLoading } =
    useProofUpdateState();

  if (!proofs?.length) {
    return (
      <Card>
        <Gap height={2.75} />
        <ShowState
          variant="empty"
          type="application"
          message="There are no documents <br /> available to show"
        />
      </Card>
    );
  }

  return (
    <Row direction="column" gap={1}>
      {!isAccountRepresentative && (
        <Row.Col>
          <BulkSelectDoc
            isDisabled={getIsLoading(proofs)}
            markSelectAll={getCheckedAll(proofs)}
            onGetCheck={({ selectAll }) => onCheckProofs(selectAll)}
            onApply={(content) => onUpdateSelected(content, proofs)}
          />
        </Row.Col>
      )}
      <Row.Col>
        <Line />
      </Row.Col>
      <Row.Col>
        <ProofListTable
          entry={entry}
          proofs={proofs}
          onDownload={handleDownload}
          onSaveNote={onUpdateNote}
          onTextFields={handleTextFields}
          onVisibility={handleVisibility}
          onCheckbox={({ value: id, isChecked }) => onCheckProof(id, isChecked)}
        />
      </Row.Col>
      <Row.Col>
        <FooterActions proofs={proofs} />
      </Row.Col>
    </Row>
  );
};

export default ProofList;
