import PropTypes from 'prop-types';
import Button, { ButtonColor, ButtonSize, ButtonVariant } from '../../atoms/Button';
import Gap from '../../atoms/Gap';
import Text, { TextColor, TextVariant } from '../../atoms/Text';
import {
  ButtonContainer,
  Container,
  Content,
  Information,
  LandingImage,
  Logo,
  StyledText
} from './styles';

export type LandingLoginProps = {
  image: string;
  title?: string;
  text?: string;
  showButton?: boolean;
  onClick?: () => void;
};

const LandingLogin: React.FC<LandingLoginProps> = (props: LandingLoginProps) => {
  const { title, text, onClick, image, showButton = true } = props;

  return (
    <Container>
      <LandingImage src={image} />
      <Content>
        <Information>
          <Logo src="./assets/images/logo-blue.svg" />
          {title && (
            <StyledText color={TextColor.primary} variant={TextVariant.big}>
              {title}
            </StyledText>
          )}
          <Gap height={1} />
          {text && (
            <Text color={TextColor.initial} variant={TextVariant.h3}>
              {text}
            </Text>
          )}
          {showButton && (
            <ButtonContainer>
              <Button
                name="Sign In"
                color={ButtonColor.primary}
                variant={ButtonVariant.contained}
                onClick={onClick}
                size={ButtonSize.big}
              >
                Sign In
              </Button>
            </ButtonContainer>
          )}
        </Information>
      </Content>
    </Container>
  );
};

LandingLogin.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  showButton: PropTypes.bool
};

LandingLogin.defaultProps = {
  image: './assets/images/income-verification-login.png',
  title: '',
  text: '',
  onClick: () => null
};

export default LandingLogin;
