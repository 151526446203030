import PropTypes from 'prop-types';
import React from 'react';
import { StyledAvatar, StyledImg } from './styles';

export type AvatarProps = {
  src: string;
  alt?: string;
  width?: string;
  height?: string;
  isDisabled?: boolean;
};

const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => {
  const { src, alt, width, height, isDisabled } = props;
  return (
    <StyledAvatar src={src} width={width} height={height}>
      <StyledImg src={src} alt={alt} isDisabled={isDisabled} />
    </StyledAvatar>
  );
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  isDisabled: PropTypes.bool
};

Avatar.defaultProps = {
  alt: '',
  src: './assets/images/default-logo.svg',
  width: '2.5rem',
  height: '2.5rem',
  isDisabled: false
};

export default Avatar;
