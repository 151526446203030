import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import { LandingLogin } from 'src/components/templates';

import { actions as authActions, selectors } from 'src/features/auth/authSlice';
import { useAppDispatch } from 'src/store';

const ROOT_DIV = document.getElementById('root');
const DOCUMENT_EVENTS = ['mousemove', 'keydown', 'mousedown', 'touchstart', 'touchmove'];

const SessionExpiredPage = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectors.user);

  useEffect(() => {
    const logoutFunction = () => {
      if (user) {
        dispatch(authActions.logout());
      }
    };

    DOCUMENT_EVENTS.forEach((event) => {
      ROOT_DIV?.addEventListener(event, logoutFunction);
    });

    return () => {
      DOCUMENT_EVENTS.forEach((event) => {
        ROOT_DIV?.removeEventListener(event, logoutFunction);
      });
    };
  }, [dispatch, user]);

  return (
    <>
      <LandingLogin
        image="./assets/images/income-verification-login.png"
        title="Detecting Fraud. Decreasing Evictions."
        text="Data-driven fraud detection software spots fraudulent financial documents other tenant screening tools ignore."
        onClick={() => dispatch(authActions.login())}
      />
      <Modal
        isBackClosable={false}
        showCancelButton={false}
        blurBackground={true}
        title="Session expired"
        text={`You session has timed out due to inactivity. Please Log in in again`}
        labelButtonConfirm={`Log in`}
        showModal={true}
        variant={ModalVariant.none}
        onConfirm={() => {
          window.location.href = '/login';
        }}
      />
    </>
  );
};

export default SessionExpiredPage;
