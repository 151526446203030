import { ReactSVG } from 'react-svg';
import { ThemeAttributes } from 'src/ts/interfaces/styled-components';
import styled, { css } from 'styled-components/macro';
import { IconColor, IconProps } from './Icon';

const getColor = (theme: ThemeAttributes, color: string) => {
  switch (color) {
    //refactor TBD: fix this so that we don't need to add more options as we add colors
    case IconColor.muted:
      return theme.color.gray[300];
    case IconColor.snappt700:
      return theme.color.snappt[700];
    case IconColor.primary:
      return theme.color.blue[600];
    case IconColor.blue700:
      return theme.color.blue[700];
    case IconColor.black:
      return theme.color.black;
    case IconColor.secondary:
      return theme.color.purple[700];
    case IconColor.white:
      return theme.color.white;
    case IconColor.gray:
      return theme.color.gray[500];
    case IconColor.gray600:
      return theme.color.gray[600];
    case IconColor.gray700:
      return theme.color.gray[700];
    case IconColor.gray900:
      return theme.color.gray[900];
    case IconColor.red:
      return theme.color.red[700];
    case IconColor.green700:
      return theme.color.green[700];
    case IconColor.yellow:
    case IconColor.yellow700:
      return theme.color.yellow[700];
    default:
      return theme.color.white;
  }
};

export const StyledReactSVG = styled(ReactSVG)<Pick<IconProps, 'color' | 'strokeColor'>>`
  ${({ color, strokeColor, theme }) => {
    if (color && (strokeColor === undefined || strokeColor === 'none')) {
      const resolvedColor = getColor(theme, color);
      return css`
        color: ${resolvedColor};

        svg {
          path {
            fill: ${resolvedColor} !important;
          }
        }
      `;
    }
    if (strokeColor) {
      const resolvedStrokeColor = getColor(theme, strokeColor);
      return css`
        svg {
          path,
          g,
          circle,
          rect,
          line,
          polyline,
          polygon,
          ellipse {
            stroke: ${resolvedStrokeColor} !important;
          }
        }
      `;
    }
  }}
`;
